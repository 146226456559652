import vue from "vue";
import VueRouter from "vue-router";
vue.use(VueRouter);

// 导航到当前路由报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
// {
//     path: '/',
//     name: 'tcapp-download-b',
//     component: () =>
//         import ('@/views/appDownloadPageB/index')
// },
{
    path: '/:id/:lang?',
    name: 'have-fun-nav',
    component: () =>
        import ('@/views/newIndex_nav'),
},
{
    path: '/',
    name: 'have-fun',
    component: () =>
        import ('@/views/newIndex_home'),
},
{
    path: '/HowtoUse/:id/:lang?',
    name: 'HowtoUse',
    component: () =>
        import ('@/views/HowtoUse'),
},
{
    path: '/TravelTips/:id/:lang',
    name: 'TravelTips',
    component: () =>
        import ('@/views/TravelTips'),
},
{
    path: '/TravelTips_detail/:id/:lang',
    name: 'TravelTips_detail',
    component: () =>
        import ('@/views/TravelTips_detail'),
},
{
    path: '/ReferenceLink/:id/:lang',
    name: 'ReferenceLink',
    component: () =>
        import ('@/views/ReferenceLink'),
},
{
    path: '/have-fun-list/:id/:lang/:filtPar?',
    name: 'have-fun-list',
    component: () =>
        import ('@/views/newIndex_list'),
},
{
    path: '/Filter/:id/:lang',
    name: 'Filter',
    component: () =>
        import ('@/views/Filter'),
},
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.afterEach((to, from) => {
  console.log(to, from);
  console.log(window.ga);
  if (window.ga) {
    window.ga("set", "page", to.fullPath);
    window.ga("send", "pageview");
  }
});

export default router;
