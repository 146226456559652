import Vue from 'vue'
import App from './App.vue'
import router from "./router/index";
import './plugins/elememt'
import './plugins/vant'
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css';
// 国际化
import i18n from './lang/index'
import eleLocale from 'element-ui/lib/locale';

import cookie from 'vue-cookie'
Vue.prototype.$cookie = cookie;

// Google Analytics
import VueGtag from "vue-gtag"
Vue.use(VueGtag, {
  config: {
    id: "G-SJFVP9NX22",
    params: {
      send_page_view: true
    }
  }
}, router)

// 设置浏览器标题-1
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

Vue.config.productionTip = false

Vue.config.lang = 'ja'
eleLocale.i18n((key, value) => i18n.t(key, value))

new Vue({
    i18n,
    router,
  render: h => h(App),
}).$mount('#app')
